<template>
  <v-expansion-panels multiple>
    <template v-for="(value, key) in theme">
      <v-expansion-panel v-if="typeof value === 'object' && value !== null" :key="currentPath(key)">
        <v-expansion-panel-header>
          <span>{{ key.toUpperCase() }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="key === 'colors'" class="section-subtitle">
            {{ path.join(' > ') }}
          </div>
          <ThemeEditorField :theme="value" :path="currentPathArray(key)" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-col v-else :key="currentPath(key)" class="input-group">
        <color-picker edit :rules="[stringRules, hexColorRule]" :label="key.toUpperCase()" :color.sync="theme[key]" />
      </v-col>
    </template>
  </v-expansion-panels>
</template>

<script>
import hexColorRule from '../../mixins/colors'

export default {
  name: 'ThemeEditorField',
  components: {
    ColorPicker: () => import('@/components/colors/ColorPicker.vue')
  },
  mixins: [hexColorRule],
  props: {
    theme: {
      type: Object,
      required: true
    },
    path: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    stringRules: v => !!v || 'Campo não pode ser nulo'
  }),
  methods: {
    currentPath(key) {
      return [...this.path, key].join('.')
    },
    currentPathArray(key) {
      return [...this.path, key]
    }
  }
}
</script>

<style scoped>
.input-group {
  margin-bottom: 0.5rem;
}
.detail-title {
  opacity: 0.8;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.24px;
  color: #000000;
  margin-bottom: 8px;
}
.section-subtitle {
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: #777;
}
</style>
